import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import { graphql, Link } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"

import bannerImageDE from '../images/helsana/Helsana-Claim_RGB_DE.png'
import bannerImageEn from '../images/helsana/Helsana-Claim_RGB_EN.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faLock, faFlaskVial, faClock } from '@fortawesome/free-solid-svg-icons'
import FadeInSection from "../components/FadeInSection"

import showcaseImage from '../images/screens/today-screen.png'
import screenQuest from '../images/screens/quest.png'
import screenChat from '../images/screens/chat.png'
import screenLibrary from '../images/screens/library.png'

export const query = graphql`
query HelsanaQueryKurzCheck($lang: String!) {
    markdownRemark(
      fields: {lang: {eq: $lang}}
      fileAbsolutePath: {regex: "/helsana-kurz-check.md/"}
    ) {
      frontmatter {
        pageTitle
        title
        description
        subtitle
        ctaButton
        feature1Title
        feature1Text
        feature2Title
        feature2Text
        feature3Title
        feature3Text
        featureScreen1Title
        featureScreen1Text
        featureScreen2Title
        featureScreen2Text
        featureScreen3Title
        featureScreen3Text
        disclaimer
      }
      html
    }
  }
`

const IndexPage = ({data, pageContext}: any) => {

    data = data.markdownRemark

    return (
        <Layout lang={pageContext.lang}>
            <div className="banner business">
                <div style={{flex:1, maxWidth: 800, margin: '0 auto'}}>
                    <h1>{ data.frontmatter.title }</h1>
                    <p>{ data.frontmatter.subtitle }</p>
                    {/*<Link to="/get-ephoria">
                        <button className="cta" style={{marginBottom: 60}}>{ data.frontmatter.ctaButton}</button>
                    </Link>*/}
                </div>

                <img src={ pageContext.lang == 'de' ? bannerImageDE : bannerImageEn} alt="Helsana"
                 style={{maxWidth: 400}}
                 className="featureImage"
                />
            </div>

            <div style={{height: 60}}></div>

            <div className="subbanner">
                <div dangerouslySetInnerHTML={{ __html: data.html }} />
                <div style={{height: 60}}></div>
                <div className="featureContainer">
                <FadeInSection speed="fast">
                    <div className="feature">
                        <img src={screenQuest} alt="Quest" style={{maxHeight: 500, marginBottom: 30}}/>
                        <h2>{data.frontmatter.featureScreen1Title}</h2>
                        <p>{data.frontmatter.featureScreen1Text}</p>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="feature">
                        <img src={screenChat} alt="Chat" style={{maxHeight: 500, marginBottom: 30}}/>
                        <h2>{data.frontmatter.featureScreen2Title}</h2>
                        <p>{data.frontmatter.featureScreen2Text}</p>
                    </div>
                </FadeInSection>

                <FadeInSection speed="slow">
                    <div className="feature">
                        <img src={screenLibrary} alt="Library" style={{maxHeight: 500, marginBottom: 30}}/>
                        <h2>{data.frontmatter.featureScreen3Title}</h2>
                        <p>{data.frontmatter.featureScreen3Text}</p>
                    </div>
                </FadeInSection>
            </div>


                <div className="featureContainer">
                    <FadeInSection speed="fast">
                    <div className="feature">
                        <div className="roundIcon" style={{backgroundColor: 'darkcyan'}}>
                            <FontAwesomeIcon icon={faFlaskVial} size="4x" color="#fff" style={{marginTop:28, marginLeft:22}}/>
                        </div>
                        <h2>{data.frontmatter.feature1Title}</h2>
                        <p>{data.frontmatter.feature1Text}</p>
                    </div>

                    </FadeInSection>

                    <FadeInSection>
                    <div className="feature">
                        <div className="roundIcon" style={{backgroundColor: 'purple'}}>
                            <FontAwesomeIcon icon={faClock} size="4x" color="#fff" style={{marginTop:28, marginLeft:28}}/>
                        </div>
                        <h2>{data.frontmatter.feature2Title}</h2>
                        <p>{data.frontmatter.feature2Text}</p>
                    </div>
                    </FadeInSection>

                    <FadeInSection speed="slow">
                    <div className="feature">
                        <div className="roundIcon" style={{backgroundColor: '#DA291C'}}>
                            <FontAwesomeIcon icon={faLock} size="4x" color="#fff" style={{marginTop:24, marginLeft:32}}/>
                        </div>
                        <h2>{data.frontmatter.feature3Title}</h2>
                        <p>{data.frontmatter.feature3Text}</p>
                    </div>

                    </FadeInSection>

                </div>



                <div className="sources" style={{fontSize: 14}}>{data.frontmatter.disclaimer}</div>

            </div>
        </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
  // on this page we pick the subtitle from the markdown file as html title
  return <Headers
        lang={pageContext.lang}
        title={data.markdownRemark.frontmatter.pageTitle}
        description={data.markdownRemark.frontmatter.description}
        location={location}
        noindex
    />
}
